import { createStore } from "vuex";
import authentication from "./modules/authentication.store";
import company from "./modules/company.store";
import estate from "./modules/estate.store";
import role from "./modules/role.store";
import user from "./modules/user.store";

export default createStore({
  state: {},
  mutations: {},
  actions: {
    clearAll({ commit }) {
      commit("authentication/resetState");
      commit("company/resetState");
      commit("estate/resetState");
      commit("role/resetState");
      commit("user/resetState");
    },
  },
  modules: {
    authentication,
    company,
    estate,
    role,
    user,
  },
});
