<template>
  <div v-if="!waiting" class="absolute">
    <div
      class="fixed top-0 left-0 z-40 max-h-full w-full overflow-y-auto border-b-4 bg-white md:z-50 md:block md:h-full md:w-72"
      :style="{ borderColor: company.primary[500] }"
      :class="showNavigation ? 'block' : 'hidden'"
    >
      <div class="pt-20">
        <div class="relative">
          <img
            class="hidden h-36 w-full object-cover md:block"
            src="/assets/background.png"
          />
          <div
            class="absolute top-0 left-0 h-full w-full opacity-25"
            :style="{
              backgroundColor: company.primary[500],
            }"
          ></div>
        </div>
        <div
          class="hidden h-1 md:block"
          :style="{ backgroundColor: company.primary[500] }"
        ></div>
        <navigation-items :company="company" />
      </div>
    </div>
    <div
      class="fixed top-0 left-0 z-50 flex h-20 w-full items-center justify-between bg-white shadow-md"
    >
      <div class="flex items-center gap-4 py-4 px-6">
        <button
          class="focus:outline-none flex h-8 w-8 items-center justify-center rounded-md bg-white focus:ring md:hidden"
          :style="{
            '--tw-ring-color': company.primary[300],
          }"
          @click="toggleNavigation"
          @blur="closeNavigation"
        >
          <Bars3Icon class="w-full text-slate-500" />
        </button>
        <img
          class="object-contain"
          :class="!company || (company && !company.logo) ? 'h-12' : 'h-8'"
          :src="getLogo(company)"
        />
      </div>
      <navigation-profile :company="company" />
    </div>
  </div>
</template>

<script>
import { Bars3Icon } from "@heroicons/vue/24/outline";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { getLogo } from "../../utils/imageUtils";
import NavigationItems from "./NavigationItems.vue";
import NavigationProfile from "./NavigationProfile.vue";
export default {
  components: {
    Bars3Icon,
    NavigationItems,
    NavigationProfile,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);

    const showNavigation = ref(false);
    const toggleNavigation = () => {
      showNavigation.value = !showNavigation.value;
    };

    return {
      company,
      getLogo,
      showNavigation,
      t,
      toggleNavigation,
      waiting: computed(() => store.state.company.waiting),
    };
  },
};
</script>
