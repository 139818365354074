<template>
  <div class="flex w-full flex-col border-t bg-white">
    <navigation-item
      v-for="item in filteredItems"
      :company="company"
      :key="item.index"
      :item="item"
      @click="navigateTo(item)"
    />
  </div>
</template>

<script>
import {
  BuildingOffice2Icon,
  HomeIcon,
  PaintBrushIcon,
  RectangleStackIcon,
  UsersIcon,
} from "@heroicons/vue/24/outline";
import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { isAdmin } from "../../utils/roleUtils";
import NavigationItem from "./NavigationItem.vue";
export default {
  components: { NavigationItem },
  props: {
    company: {
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const user = computed(() => store.state.authentication.user);

    const items = reactive([
      { icon: HomeIcon, label: "home", path: "/" },
      {
        icon: BuildingOffice2Icon,
        label: "estates",
        path: "/estates",
      },
      {
        icon: RectangleStackIcon,
        label: "tasks",
        path: "/tasks",
      },
      {
        divider: true,
      },
      {
        icon: UsersIcon,
        label: "personnel",
        path: "/personnel",
      },
      {
        icon: PaintBrushIcon,
        label: "branding",
        path: "/branding",
        meta: {
          adminRequired: true,
        },
      },
    ]);

    const filteredItems = computed(() => {
      return items.filter((item) => {
        if (item.meta?.adminRequired && !isAdmin(user.value)) {
          return false;
        }
        return true;
      });
    });

    const navigateTo = (item) => {
      router.push(item.path);
    };

    return {
      filteredItems,
      navigateTo,
    };
  },
};
</script>
