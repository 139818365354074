import { db, functions } from "@/firebase";
import router from "@/router";
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    contacts: [],
    personnel: [],
    status: {},
    user: {},
    users: [],
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createEmployee({ commit }, data) {
    try {
      commit("wait");
      const createEmployee = httpsCallable(functions, "createEmployee");
      const res = await createEmployee(data);

      if (!res.data.success) throw "unknown";
      commit("addEmployee", res.data.data);
      commit("setSuccess", "user_created");
      router.push("/personnel");
    } catch (error) {
      commit("failure", error);
    }
  },
  async getEstateContacts({ commit }, contactIds) {
    try {
      commit("wait");
      const q = query(
        collection(db, "users"),
        where(documentId(), "in", contactIds)
      );
      const querySnapshot = await getDocs(q);
      const users = querySnapshot.docs.map((doc) => {
        const user = doc.data();
        user.id = doc.id;
        return user;
      });

      commit("setContacts", users);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getEstateUsers({ commit }, estateId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "users"),
        where(`estates.${estateId}`, ">=", "")
      );
      const querySnapshot = await getDocs(q);
      const users = querySnapshot.docs.map((doc) => {
        const user = doc.data();
        user.id = doc.id;
        return user;
      });

      commit("setUsers", users);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getPersonnel({ commit }, companyId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "users"),
        where("companyId", "==", companyId)
      );
      const querySnapshot = await getDocs(q);
      const personnel = querySnapshot.docs.map((doc) => {
        const user = doc.data();
        user.id = doc.id;
        return user;
      });

      commit("setPersonnel", personnel);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getUser({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const user = docSnap.data();
        user.id = docSnap.id;
        commit("setUser", user);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addEmployee(state, employee) {
    state.personnel.push(employee);
    state.waiting = false;
  },
  addUser(state, user) {
    state.users.push(user);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setContacts(state, contacts) {
    state.contacts = contacts;
    state.waiting = false;
  },
  setPersonnel(state, personnel) {
    state.personnel = personnel;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  setUser(state, user) {
    state.user = user;
    state.waiting = false;
  },
  setUsers(state, users) {
    state.users = users;
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getUser(state) {
    return state.user;
  },
};

const user = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default user;
