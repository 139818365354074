export const getRoleName = (roleId, roles) => {
  const found = roles.find((role) => role.id == roleId);
  return found ? found.label : null;
};

export const getRoles = (roleIdsString, roles) => {
  const roleIds = roleIdsString.replace(/\s/g, "").split(",");
  return roles.filter((role) => roleIds.includes(role.id));
};

export const getRoleColor = (roleLabel) => {
  switch (roleLabel) {
    case "admin":
      return "rounded-full bg-red-700 px-2 py-1 text-sm text-white";
    case "manager_admin":
      return "rounded-full bg-purple-700 px-2 py-1 text-sm text-white";
    case "manager_basic":
      return "rounded-full bg-yellow-500 px-2 py-1 text-sm text-black";
    case "board_member":
      return "rounded-full bg-indigo-500 px-2 py-1 text-sm text-white";
    case "shareholder":
      return "rounded-full bg-blue-500 px-2 py-1 text-sm text-white";
    case "resident":
      return "rounded-full bg-yellow-500 px-2 py-1 text-sm text-black";
  }
};

export const isAdmin = (user) => {
  if (user.roleId) {
    if (
      user.roleId === "8df5ZocFUG4Uv47GI594" ||
      user.roleId === "MassXRUJwqqUa1H89U78" ||
      user.roleId === "SiiFYAZFg1dnQz53O98o"
    ) {
      return true;
    }
  }
  return false;
};

export const isBoardMember = (user) => {
  const estate = user.estates[user.currentEstate];
  return estate.roleId === "zKJOhtNoNRZ2B1qnSpnj";
};

export const isShareholder = (user) => {
  const estate = user.estates[user.currentEstate];
  return estate.roleId === "1BDHJaIl5DIZr0EHgnfo";
};
