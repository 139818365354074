import { auth } from "@/firebase";
import store from "@/store";
import { createRouter, createWebHistory } from "vue-router";
import { isAdmin } from "../utils/roleUtils";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      authRequired: true,
      breadcrumb: [],
    },
  },
  {
    path: "/branding",
    name: "Branding",
    component: () => import("../views/Branding.vue"),
    meta: {
      authRequired: true,
      adminRequired: true,
      breadcrumb: ["branding"],
    },
  },
  {
    path: "/complete",
    name: "Complete",
    component: () => import("../views/Complete.vue"),
  },
  {
    path: "/estates",
    name: "Estates",
    component: () => import("../views/Estates.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates"],
    },
  },
  {
    path: "/estates/:estateId",
    name: "Estate",
    component: () => import("../views/Estate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["estates", "estate"],
    },
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("../views/Forgot.vue"),
  },
  {
    path: "/sign-in",
    name: "Sign-in",
    component: () => import("../views/SignIn.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["profile"],
    },
  },
  {
    path: "/profile/edit",
    name: "Profile edit",
    component: () => import("../views/ProfileEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["profile", "edit"],
    },
  },
  {
    path: "/personnel",
    name: "Personnel",
    component: () => import("../views/Personnel.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["personnel"],
    },
  },
  {
    path: "/personnel/create",
    name: "User create",
    component: () => import("../views/UserCreate.vue"),
    meta: {
      adminRequired: true,
      authRequired: true,
      breadcrumb: ["personnel", "create"],
    },
  },
  {
    path: "/personnel/:userId",
    name: "User",
    component: () => import("../views/User.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["personnel", "user"],
    },
  },
  {
    path: "/personnel/:userId/edit",
    name: "User edit",
    component: () => import("../views/UserEdit.vue"),
    meta: {
      adminRequired: true,
      authRequired: true,
      breadcrumb: ["personnel", "user", "edit"],
    },
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () => import("../views/Tasks.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["tasks"],
    },
  },
  {
    path: "/tasks/:taskId",
    name: "Task",
    component: () => import("../views/Task.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["tasks", "task"],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
});

router.beforeResolve(async (to, from, next) => {
  const currentUser = auth.currentUser;
  const idTokenResult = await currentUser?.getIdTokenResult();
  const user = store.state.authentication.user;
  if (to.meta.authRequired && !currentUser) {
    return next("/sign-in");
  } else if (to.meta.authRequired && idTokenResult?.claims.password_reset) {
    return next("/complete");
  } else if (to.meta.adminRequired && !isAdmin(user)) {
    return next("/");
  } else {
    return next();
  }
});

export default router;
